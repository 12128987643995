import React from 'react'
import PropTypes from 'prop-types'
// import { StaticQuery, graphql } from 'gatsby'

import './reset.scss'
import './fonts.scss'
import './index.scss'
import './buttons.scss'
import './imprint.scss'

const Layout = ({ children }) => (
  <div className="content">
    <div className="content__inner">
      {children}
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
