import React from 'react'
import Layout from 'components/layout'
import { Link } from 'gatsby'

function PrivacyPage() {
  return (
    <Layout>
      <div className="textwrapper">
      <Link to="/" className="link--back">Back</Link>
      <h1 className="textwrapper__h1">
        DATENSCHUTZ&shy;ERKLÄRUNG<br />
        </h1>
          <p>EINSCHLIESSLICH SOCIAL MEDIA UND ANALYSE TOOL</p>
        <ol>
          <li>
            <h4>
            Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des betrieblichen Datenschutzbeauftragten
            </h4>
            <p>
            Diese Datenschutz-Information gilt für die Datenverarbeitung durch
            den Verantwortlichen:
            </p>
            <address>
            Maschin Records<br />
            Urbanstrasse 115<br />
            10967 Berlin<br />
            E-Mail: christoph(at)redroad.at
            </address>
          </li>
          <li>
            <h4>
            Erhebung und Speicherung personenbezogener Daten sowie Art und
            Zweck von deren Verwendung
            </h4>
            <h5>Beim Besuch der Website</h5>

            <p>Beim Aufrufen unserer Website www.beattherich.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur Löschung nach 54 Tagen gespeichert:</p>
            <ul>
              <li>IP-Adresse des anfragenden Rechners</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Name und URL der abgerufenen Datei</li>
              <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
              <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
            </ul>
            <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
            <ul>
              <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
              <li>Auswertung der Systemsicherheit und -stabilität</li>
              <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>
            Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S.1 lit. a. und b. DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
            Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter Ziff. 4 und 5 dieser Datenschutzerklärung.
            </p>

            <h5>Bei Anmeldung für unseren Newsletter</h5>
            <p>
            Sofern Sie nach Art. 6 Abs. 1 S. lit. a. DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang des Newsletters ist die Angabe einer E-Mail-Adresse ausreichend.
            Die Abmeldung ist jederzeit möglich, die Abmeldefunktion finden Sie in jedem Newsletter.
            </p>
          </li>

          <li>
            <h4>Weitergabe von Daten</h4>
            <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.</p>
            <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
            <ul>
              <li>Sie nach Art. 6 Abs. 1 S. 1 lit. a. und/oder b. DSGVO eingewilligt haben, zur Erfüllung von vertraglichen Beziehungen und/oder dieses zur Durchführung vorvertraglicher Maßnahmen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben</li>
              <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht</li>
              <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist</li>
            </ul>
          </li>

          <li>
            <h4>Cookies</h4>
            <p>
            Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
            In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
            Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
            Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unserer Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
            Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.
            Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. b. DSGVO erforderlich.
            Die meisten Browser akzeptieren Cookies automatisch.
            Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.
            </p>
            <p>Welche Website welche Cookies verwendet können Sie über entsprechende Software prüfen. Unsere Tracker Software Empfehlung: www.ghostery.com. Einfach downloaden und mit einem Click für jede Seite die entsprechende Analyse erhalten. Das Ghostery icon befindet sich nach dem DL direkt neben der URL Eingabe Leiste in ihrem Browser.</p>
          </li>

          <li>
            <h4>Analyse-Tools</h4>

            <h5>Tracking-Tools</h5>
            <p>
            Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. b. DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Website sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.
            Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.
            </p>

            <h6>Google Analytics</h6>
            <p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der</p>
            <address>
              <p>Google Inc.</p>
              <p>1600 Amphitheatre Parkway</p>
              <p>Mountain View</p>
              <p>CA 94043</p>
              <p>USA</p>
              <p>https://www.google.de/intl/de/about</p>
            </address>

            <p>im Folgenden „Google“ genannt. In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziffer. 4) verwendet. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie:</p>
            <ul>
              <li>Browser-Typ/-Version</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer-URL (die zuvor besuchte Seite)</li>
              <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
              <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>
            werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
            Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich genutzt werden können.
            Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren (https://tools.google.com/dlpage/gaoptot?hl=de).
Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.
            Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten; können Sie die Erfassung durch Google Analytics zudem in Ihren Einstellungen verhindern. Es wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google-Analytics-Hilfe https://support.google.com/analytics/answer/6004245?hl=de).
            </p>
          
            <h6>Google Adwords Conversion Tracking und Remarketing</h6>
         
            <p>
            Um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unserer Website Ihre Daten auszuwerten, nutzen wir ferner das Google Conversion Tracking. Dabei wird von Google Adwords ein Cookie (Ziffer 4) auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf unsere Website gelangt sind.
            Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten der Website des Adwords-Kunden und das Cookie ist noch nicht abgelaufen, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
            Jeder Adwords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für Adwords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
            Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie auch das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain www.googleadservices.com blockiert werden. Googles Datenschutzbelehrungen zum Conversion-Tracking finden Sie hier (https://services.google.com/sitestats/de.html).
            </p>
            <p>
            Für weitere Details zur Datenverarbeitung durch Google Adwords verweisen wir auf die entsprechenden Datenschutzerklärungen:
            </p>
          	<p>• Google Adwords: https://policies.google.com/privacy?hl=en&gl=uk</p>

            <p>Im Zusammenhang mit der Verwendung von Google Adwords erfolgt eine Übermittlung Ihrer personenbezogenen Daten in die USA. Im Fall der USA hat die EU-Kommission nicht beschlossen, dass ein angemessenes Datenschutzniveau i.S.d. DS-GVO besteht; ein solcher Angemessenheitsbeschluss (Art. 45 DS-GVO) besteht nicht. Google LLC. unterliegt jedoch dem EU-U.S. Privacy Shield. Dadurch ist ein angemessener Schutz Ihrer personenbezogenen Daten gewährleistet. Den vollständigen Text des US Privacy Shield Frameworks können Sie unter folgendem Link aufrufen:</p>
            <p>
            	•	https://www.privacyshield.gov/servlet/servlet.FileDownload?file=015t00000004qAg
            </p>
            <p>
            Wir benutzen Google Adwords für unsere Website. Google AdWords ist ein Online-Werbeprogramm von Google. Dabei verwenden wir die Remarketing-Funktion. Damit können wir mit Hilfe von Cookies Nutzern unserer Website auf deren Interessen basierende Werbeanzeigen präsentieren. Dafür wird die Interaktion der Nutzer auf unserer Website analysiert, z.B. für welche Angebote sich der Nutzer interessiert hat, um den Nutzern auch nach dem Besuch unserer Website auf anderen Seiten zielgerichtete Werbung anzeigen zu können. Die eingesetzten Cookies dienen zur eindeutigen Identifikation eines Webbrowsers auf einem bestimmten Computer und nicht zur Identifikation einer Person; personenbezogene Daten werden nicht gespeichert. Sie können die Verwendung von Cookies durch Google deaktivieren, indem Sie dem nachfolgenden Link folgen und das dort bereitgestellte Plug-In herunterladen und installieren:
            </p>
            <p>
            	•	https://www.google.com/settings/ads/plugin.
            </p>
            <p>
            Nähere Informationen zu Google Remarketing sowie die Datenschutzerklärung von Google können Sie einsehen unter:
            </p>
            <p>
            	•	http://www.google.com/privacy/ads/.
            </p>
            <p>
            Ferner verwenden wir im Rahmen der Nutzung des Google AdWords-Dienstes das sog. Conversion Tracking. Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Endgerät abgelegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Die Informationen, die durch das Conversion-Cookie eingeholt werden, dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben.
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Darüber hinaus können Sie interessenbezogene Anzeigen auf Google sowie interessenbezogene Google Anzeigen im Web (innerhalb des Google Displaynetzwerks) in Ihrem Browser deaktivieren, indem Sie unter  http://www.google.de/settings/ads die Schaltfläche „Aus“ aktivieren oder die Deaktivierung unter  http://www.aboutads.info/choices/ vornehmen. Weitere Informationen zu Ihren diesbezüglichen Einstellungsmöglichkeiten und dem Datenschutz bei Google finden Sie unter  https://www.google.de/intl/de/policies/privacy/?fg=1.
            </p>
            <h6>Facebook Pixel</h6>
            <p>
            Wenn Sie unsere Webseiten besuchen, beobachten und dokumentieren wir Ihr Nutzerverhalten, um Ihnen auf Basis dieser Daten individuelle Empfehlungen auf unseren Webseiten anzuzeigen. Hierzu verarbeiten wir folgende personenbezogene Daten:
            </p>
            <ul>
            	<li>IP-Adresse</li>
              <li>UU-ID</li>
              <li>WEB-ID</li>
              <li>Device Fingerprints</li>
              <li>Browser Fingerprints</li>
              <li>Cookies</li>
              <li>Geo IP-location</li>
            </ul>
            <p>
              Wir verarbeiten Ihre personenbezogenen Daten auf Grund unseres berechtigten Interesses, Produktempfehlungen auszuspielen und Marketingmaßnahmen durchzuführen, auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f) DS-GVO.
Für de Verarbeitung setzen wir Facebook Pixel ein. Hier werden von uns nur die Standard-Funktionen eingesetzt. Für weitere Details zur Datenverarbeitung durch Facebook Pixel verweisen wir auf die entsprechenden Datenschutzerklärungen:
            </p>
            <ul>
          	<li>Facebook Pixel: https://www.facebook.com/privacy/explanation</li>
           Zusammenhang mit der Verwendung Facebook Pixel erfolgt eine Übermittlung Ihrer personenbezogenen Daten in die USA. Im Fall der USA hat die EU-Kommission nicht beschlossen, dass ein angemessenes Datenschutzniveau i.S.d. DS-GVO besteht; ein solcher Angemessenheitsbeschluss (Art. 45 DS-GVO) besteht nicht. Facebook, Inc. unterliegen jedoch dem EU-U.S. Privacy Shield. Dadurch ist ein angemessener Schutz Ihrer personenbezogenen Daten gewährleistet. Den vollständigen Text des US Privacy Shield Frameworks können Sie unter folgendem Link aufrufen:
           
          	<li>https://www.privacyshield.gov/servlet/servlet.FileDownload?file=015t00000004qAg</li>
          
          	<li>„Facebook Custom Audiences“</li>
          
          	<li>Weiterhin verwendet die Website die Remarketing-Funktion „Custom Audiences“ der Facebook Inc. („Facebook“). Dadurch können Nutzern der Website im Rahmen des Besuchs des sozialen Netzwerkes Facebook oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Facebook-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.</li>
          
          	<li>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz dieses Tools durch Facebook erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von Facebook Custom Audiences erhält Facebook die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Facebook registriert sind, kann Facebook den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert.</li>
          
          	<li>Die Deaktivierung der Funktion „Facebook Custom Audiences“ ist hier und für eingeloggte Nutzer unter https://www.facebook.com/settings/?tab=adsmöglich.</li>
          
          	<li>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Weitere Informationen zur Datenverarbeitung durch Facebook erhalten Sie unter https://www.facebook.com/about/privacy</li>
            </ul>
          </li>
          <li>
            <h4>Betroffenenrechte</h4>
       
            <p>Sie haben das Recht:</p>
            <ul>
      	<li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorie von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung; Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
              <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
              <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
              <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
              <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
              <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und</li>
              <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li>
            </ul>
          </li>
          <li>
            <h4>Widerspruchsrecht</h4>
       
            <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lt. F DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.</p>
          </li>
          <li>
            <h4>Datensicherheit</h4>
            <p>
      Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256-Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbol in der unteren Statusleiste Ihres Browsers.
      Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
            </p>
          </li>
          <li>
            <h4>Aktualität und Änderung dieser Datenschutzerklärung</h4>
            <p>
      Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.
      Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter bilderbuch-musik.at von ihnen abgerufen werden.
            </p>
          </li>
        </ol>
      </div>
    </Layout>
  )
}
export default PrivacyPage